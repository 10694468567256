<template>
	<div class="wall">
		<!-- <img src="../../assets/img/welimg.png" class="login_logo" alt=""> -->
		<div class="login_box"  style="margin-top: 80px;">
			<div class="login_input">
				
				<img src="../../assets/img/login_msg.png" class="input_img" alt="">
				<input type="text" class="input" :placeholder="$t('QSRACCOUNT')" v-model.trim="account">
				
			</div>
			<el-select :placeholder="$t('QXZDQ')" v-model="country_code" v-if="isEmail==0" style="margin-right:20px;">
			    <el-option
			      v-for="(item,index) in codelist"
			      :key="index"
			      :label="item.label"
			      :value="item.value" >
			    </el-option>
			</el-select>
			<div>
				<el-button type="primary" @click="getCode"><!-- 免费注册 -->{{$t('QY.MFZC')}}</el-button>
			</div>
		</div>
		
		<div class="login_title">
			<!-- 其他登陆方式 -->
			<div>
				<div>{{$t('login.QTDLFS')}}</div>
				<div>{{$t('login.QTDLFS2')}}</div>
			</div>
		</div>
		<div class="login_list">
			<img src="../../assets/img/google.png" @click="login_google" class="face" alt="">
			<!-- <img src="../../assets/img/facebook.png" @click="login_fb" class="face" style="width: 182px;" alt=""> -->
			<img src="../../assets/img/linked.png" class="face" @click="login_link" style="width: 133px;" alt="">
			<img src="../../assets/img/yahoo.png" @click="login_yahoo" class="face" style="width: 123px;" alt="">
		</div>
		
			<div class="login_btn" @click="tologin">
				<!-- <img src="../../assets/img/add.png" class="add" alt=""> -->
				<!-- 已有账号，立即登陆 -->{{$t('QY.YYZH')}}
			</div>
		
		<div class="login_msg">
			* <!-- 注册成为会员，即视为您同意使用条款和 -->{{$t('QY.ZCCWHY')}} <router-link to="/tiaokuan"  style="color: #567BB6;">{{ $t("header.SYTK") }}</router-link> {{$t('AND')}} <router-link to="/yinsi" style="color: #567BB6;">{{ $t("header.YSZC") }}</router-link> {{$t('QY.ZCCWHY2')}}
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				account:'',
				checked:false,
				type:0,
				codelist:[{value: '+81', label: '日本'}],
				country_code:'+81',
				isEmail:0
			};
		},
		created(e) {
			if(this.$route.query.account){
				this.account=this.$route.query.account;
			}
			this.getQuhao();
			this.type=this.$route.query.type||0;
			// console.log('type',this.type,this.$route);
			
		},
		
		watch: {
		    $route() {
		      this.type=this.$route.query.type||0;
		      console.log('type',this.type);
		    },
			account(){
				this.isEmail=this.account.indexOf('@')>-1?1:0;
				//console.log(this.account);
			}
		},
		methods:{
			async login_link(){
				let res=await this.$request.get('/api/custauth/getLinkedinURL',{redirect_uri:'https://yours.online/app/tencent/callback',user_role:this.type});
				 window.location.href=res.data.url;
			},
			async login_google(){
				let res=await this.$request.get('/api/custauth/getGoogleURL',{redirect_uri:'https://yours.online/app/tencent/callback/type/google',user_role:this.type});
				 window.location.href=res.data.url;
			},
			async login_yahoo(){
				let res=await this.$request.get('/api/custauth/getYahoolURL',{redirect_uri:'https://yours.online/app/tencent/callback/type/yahoo',user_role:this.type});
				 window.location.href=res.data.url;
			},
			async login_fb(){
				let res=await this.$request.get('/api/custauth/getFacebookURL',{redirect_uri:'https://yours.online/app/tencent/callback/type/facebook',user_role:this.type});
				 window.location.href=res.data.url;
			},
			async getQuhao(){				
				let res=await this.$request.get('/api/sysdict/getCountryCode');
				this.codelist=res.data.records;
			},
			tologin(){
				if(this.type==1){
					this.$router.push({path:'/login_QY'});
				}else{
					this.$router.push({path:'/login'});
				}
			},
			async getCode(){				
				let res=await this.$request.get('/api/custauth/sendValidateCode',{account:this.account,identify_cate:0,country_code:this.country_code});
				this.$router.push({path:'/register2',query: { account: this.account,sign:res.data.sign,type:this.type,country_code:this.country_code}});
				
			},
		}
	};
</script>

<style lang="less" scoped>
	.wall{
		// padding: 30px;
		height: 100%;
		background-color: #F3F1EA;
		font-size: 14px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 100px;
		.login_logo{
			
			margin-top: 90px;
		}
		.login_box{
			width: 1329px;
			height: 103px;
			background: #FFFFFF;
			box-shadow: 0px 10px 26px 0px rgba(129,128,125,0.11);
			border-radius: 7px;
			margin-top: 70px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 20px;
			padding-right: 13px;
			box-sizing: border-box;
			.el-button{
				width: 189px;
				height: 77px;
				background-color: #567BB6;
				border: none;
			}
			.el-input__inner{border:none;}
			.login_input{
				display: flex;
				align-items: center;
				flex:1;
				height: 80px;
				
				.input_img{
					width: 30px;
					height: 30px;
					margin-right: 17px;
				}
				.input{
					border: none;
					font-size: 14px;
					width: 600px;
					height: 80px;
				}
				input::-webkit-input-placeholder{color:#B3C8E9;}
				
				input::-moz-placeholder{color:#B3C8E9;}
				
				input:-ms-placeholder{color:#B3C8E9;}
			}
		}
		.login_text{
			width: 1329px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-top: 10px;
			font-size: 14px;
			el-checkbox{
				height: 20px;
			}
			.red{
				line-height: 20px;
				color: #B65664!important;
				margin-left: 40px;
			}
		}
		.login_title{
			width: 1329px;
			padding: 0 37px;
			box-sizing: border-box;
			color: #567BB6;
			font-size: 21px;
			padding-top: 70px;
			display: flex;align-items: center;justify-content: center;text-align: center;line-height: 1.6;
			&::before,&::after{
				content:"";border-bottom: 1px solid #567BB6;flex:1;
			}
			&::before{margin-right: 20px;}
			&::after{margin-left: 20px;}
		}
		.login_list{
			width: 950px;
			justify-content: space-between;
			display: flex;
			align-items: center;
			padding-top: 30px;
			.face{
				width: 140px;
				cursor: pointer;
				margin: 0 60px;
			}
		}
		.login_btn{
			min-width: 360px;
			padding: 0 15px;
			height: 60px;
			background: #F3F1EA;
			border-radius: 7px;
			border: 1px solid #567BB6;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 22px;
			color: #567BB6;
			margin-top: 90px;
			.add{
				width: 21px;
				height: 21px;
				margin-right: 10px;
			}
		}
		.login_msg{
			font-size: 14px;
			color: #B65664;
			margin-top: 10px;
		}
	}
</style>